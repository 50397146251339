import React from "react";
import { motion } from "framer-motion";

const ComingSoon = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-900 via-purple-700 to-gray-900 text-white">
      {/* Animasyonlu Başlık */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="text-center"
      >
        <h1
          className="text-6xl font-extrabold mb-4"
          style={{
            textShadow: "2px 2px 10px rgba(0, 0, 0, 0.5), 0 0 20px rgba(255, 255, 255, 0.2)",
          }}
        >
          innowex
        </h1>
        <p className="text-xl text-gray-300">
          Yeni bir dijital deneyimle tanışmaya hazır olun!
        </p>
      </motion.div>

      {/* Animasyonlu Geri Sayım */}
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
        className="mt-10 text-center"
      >
        <h2 className="text-4xl font-semibold">Hazırlanıyor...</h2>
        <p className="text-lg text-gray-400 mt-2">
          Sitemiz kısa süre içinde yayında olacak.
        </p>
      </motion.div>

      {/* İletişim Bilgileri */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
        className="mt-10 text-center space-y-3"
      >
        <p>
          <strong>Telefon:</strong>{" "}
          <a
            href="tel:05079110466"
            className="text-yellow-400 hover:underline"
          >
            0507 911 04 66
          </a>
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <a
            href="mailto:info@innowex.com.tr"
            className="text-yellow-400 hover:underline"
          >
            info@innowex.com.tr
          </a>
        </p>
        <p>
          <strong>Instagram:</strong>{" "}
          <a
            href="https://www.instagram.com/innowex.digital"
            target="_blank"
            rel="noopener noreferrer"
            className="text-yellow-400 hover:underline"
          >
            @innowex.digital
          </a>
        </p>
      </motion.div>

      {/* Footer */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1 }}
        className="absolute bottom-6 text-sm text-gray-400"
      >
        &copy; {new Date().getFullYear()} İnnowex. Tüm hakları saklıdır.
      </motion.div>
    </div>
  );
};

export default ComingSoon;
